import React from 'react';
import Page from '../components/Page';
import Logo from '../components/Logo';
import Menu from '../components/Menu';
import Sponsor from '../components/Sponsor';
import {Markdown} from '../components/Markdown';
import {useSiteData} from '../hooks/useSiteData';
import {graphql, Link} from "gatsby";
import VideoPlayer from "../components/VideoPlayer";
import Seo from "../components/seo";

export default function music({data: {music,pageSettings} }) {
/*	const {advertise} = useSiteData();*/

	return <Page orientation="portrait">

		<Seo remoteMetaData={music} title={music.titleSEO} />

		<div className="game-box">
			<div className="game-box-inner">
				<header className="header-area">
					<div className="container">
						<div className="header-grid">
							<div className="box-1">
							</div>
							<div className="box-2"/>

							<Menu/>
						</div>
					</div>
				</header>

				<div className="content" style={{paddingBottom: 91.3333}}>
					<div className="gen-page nav-grid">
						<div className="logo-box">
							<Link to="/">
								<Logo/>
							</Link>
						</div>
						<div className="button-box">
							<a href="https://shop.keenkids.com.au" target="_blank" className="kk-btn-rounded btn-primary3">Shop</a>
						</div>
					</div>


					<h1>{music.header}</h1>

					<Markdown>{music.bodyText}</Markdown>

					<div className = "App">
						<a href = {music.scores.URL} target = "_blank">Download music notes</a>
					</div>

					<div className="video-container-16-9">
						<VideoPlayer src={music.musicVideo} width="100%" height={200} />
					</div>
					<Sponsor />
				</div> {/* / .content */}
			</div> {/* / .game-box-inner */}

		</div> {/* / .game-box */}
	</Page>;
}

export const query = graphql`
    query {
        music {
            header
			bodyText
			musicVideo
			titleSEO
			descriptionSEO
			ogImage {
				localFile {
					publicURL
				}
			}			
			scores{
				URL
				hash
				name
			}
        }
    }
`;
